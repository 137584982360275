.students-list {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 10px 0;
  text-align: center;
  color: #202020;
}

.students-list form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  border-radius: 10px;
  padding: 10px;
  text-transform: none;
  text-align: start;
  border: 1px solid rgb(195, 194, 194);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.student label {
  width: 100%;
}

.student h5,
.student h6 {
  margin: 0;
}

.student.selected {
  border: 2px solid #007bff; /* Thick blue border */
  background-color: #eaf4ff; /* Light blue background */
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2); /* Slightly heavier shadow */
}
