.bottom-navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 100;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #000;
  }
  
  .nav-item svg {
    font-size: 24px;
    margin-bottom: 5px;
    transition: color 0.3s ease;
  }
  
  @media (min-width: 768px) {
    .bottom-navbar {
      display: none; /* Hide on larger screens */
    }
  }
  