.exam-mark-section {
    margin: 15px;
    /* height: 100%; */
    margin-bottom: 100px;
}

.exam-mark-section .filter-button{ 
    border-radius: 20px;
    font-size: 12px;
    margin-bottom: 20px;
}

.exam-mark-section .filter-button .filter-icon{
    margin-right: 8px;
}

.modal-open{
    height: 100%;
}

.modal h5{
    font-weight: bolder;
    margin-bottom: 30px;
}

.modal .date-range-button, .exam-type-button, .subjects-button{
    width: auto;
    height: 45px;
    border-radius: 10px;
    margin-right: 10px;
    color: black;
    border: 1px solid gray;
}
  
.modal-header-center .modal-title {
    flex-grow: 1;
    text-align: center;
    margin: 0;
} 

.exam-mark-section .modal .modal-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .exam-mark-section .modal-footer .modal-reset-button, .modal-apply-filter{
    width: 200px;
    height: 50px;
    border-radius: 10px;
} */