.course-enrolled-wrapper{
    display: flex;
    justify-content: center;
    /* position: relative; */
}

.course-enrolled-page {
    width: 100%;
}

.course-enrolled-page h2 {
    text-align: center;
    margin: 10px 0 30px 0;
}

tr:hover {
    cursor: pointer;
} 

@media (min-width: 991px){
    .course-enrolled-page{
        width: 80%;
    }
}