.active {
    background-color: #dcdcdc;
    border-radius: 10px;
}

.wrapper{
    width: 100%;
    display: flex;
    background: rgba(226, 226, 226, 1);
    justify-content: space-around;
    position: sticky;
    top: 0;
}

.wrapper .current-title {
    display: none;
  }

nav {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .title {
    font-size: 1.5rem;
    margin: 0.5rem;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

nav .logo {
    width: auto;
    height: 50px;
}

nav ul {
    display: flex;
}

nav ul li{
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    background-color: #999c9a;
}

nav ul li a:hover {
    color: black;
}

nav .menu {
    display: none;
    /* position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem; */
}

@media (max-width: 991px){
    .wrapper {
        display: block;
    }

    .wrapper .current-title {
        display: block;
        font-size: 18px;
        color: black;
        /* margin-left: 45px; */
        margin-left: 15px;
      }

    nav .menu {
        display: flex;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        flex-direction: column;
        justify-content: space-between;
        width: 2.25rem;
        height: 3rem;
        left: 0;
        padding-left: 10px;
      }

    nav {
        width: 100%;
        /* flex-direction: column;
        align-items: start; */
        display: flex;
        flex-direction: row-reverse;
    }

    nav .logo{
        padding-right: 5px;
    }

    nav ul {
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
        background: rgba(226, 226, 226, 1);
        transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
        max-height: 0;
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        padding: 0;
      }

    nav ul.open {
        /* display: flex; */
        max-height: 500px;
        padding: 1rem 0;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}
