.login-nav-wrapper {
  width: 100%;
  background-color: rgba(226, 226, 226, 1);
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;
}

.login-nav {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .logo {
  width: auto;
  height: 50px;
}

@media (max-width: 991px) {
  .login-nav-wrapper {
    display: block;
  }

  .login-nav {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }

  nav .logo {
    padding-right: 5px;
  }
}

@media (prefers-color-scheme: dark) {
  .login-nav-wrapper {
    background-color: #fff;
  }
}
