.register-page {
  display: flex;
  align-items: start;
  width: 100%;
}

.register-page .container-fluid {
  margin-top: 20px;
}

.register-page .container-fluid .card {
  position: static;
}

.register-page img {
  border-radius: 20px;
}

@media (min-width: 926px) {
  .register-page div:not([class]) {
    width: 60%;
  }
}
