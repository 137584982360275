.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 20px;
    text-align: center;
  }
  
  .imgg {
    width: 100%;
    max-width: 600px;
  }
  
  .text {
    font-size: 20px;
    margin-bottom: 40px;
  }
  
  .error-text {
    padding: 20px;
    font-family: 'Cursive', sans-serif;
    font-size: 24px;
  }
  
  .error-button {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 15px;
    background: #6200ee;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .error-button:hover {
    background-color: #aaad00;
    transition: 0.5s;
  }
  
  @media (max-width: 767px) {
    .error-page {
      margin-top: 20px;
    }
  
    .imgg {
      width: 80%;
      max-width: 300px;
    }
  
    .text {
      font-size: 16px;
      margin-bottom: 20px;
    }
  
    .error-text {
      font-size: 18px;
    }
  
    .error-button {
      font-size: 1rem;
      padding: 10px;
    }
  }
  