

.profile-page-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-page{
    width: 90%;
}

.profile-page h2{
    text-align: center;
    margin: 10px 0 10px 0;
}

.logout-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout-button{
    cursor: pointer;
    color: rgb(35, 154, 55);
    font-weight: 600;
}

.save-button{
    width: 100%;
    height: 60px;
    font-size: 1em;
    letter-spacing: 3px;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;

}

.invalid-feedback {
    z-index: -1 !important;
}

@media (min-width: 991px) {
    .save-button {
        position: static;
        bottom: 1;
        border-radius: 8px;
    }
}