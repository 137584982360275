
.student-details-section {
    display: flex;
    height: 100px; /* Increased height for better visibility */
    width: 100%;
    margin-top: 20px;
}

.profile-pic-wrapper {
    min-width: 100px; /* Increased width for larger profile picture */
    margin-right: 20px;
}

.profile-pic {
    height: 100px; /* Match height with parent container */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}

.student-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course-name {
    font-size: 14px;
    color: #555;
}