.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background: linear-gradient(
    132deg,
    rgba(0, 0, 0, 0.5635504201680672) 0%,
    rgba(173, 173, 173, 1) 52%,
    rgba(149, 149, 149, 1) 100%
  );
  background-color: aqua;
  padding-bottom: 100px;
}

.login-page h4{
    text-align: center;
}

.otp-login-button {
    position: static;
    bottom: 1;
    border-radius: 8px;
}

button.MDBBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .text-center {
    text-align: start !important;
}

.phone-input-wrapper {
    max-width: 100%; /* Ensures the input doesn't overflow */
    width: 100%; /* Make it responsive */
  }
  
  .phone-input-wrapper .react-tel-input .form-control {
    width: 100%; /* Ensures the input field itself is fully responsive */
  }
  
  .phone-input-wrapper .react-tel-input {
    display: flex;
    justify-content: space-between; /* Keeps the flag and input aligned */
  }
  

@media (max-width: 991px) {
  .login-page {
    padding-bottom: 150px;
  }

  .login-page .card {
    width: 80%;
    height: 180px;
  }

  .otp-login-button {
    width: 100%;
    height: 80px;
    font-size: 1em;
    letter-spacing: 3px;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}