/* CourseDetails.css */

.course-details-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-details-page {
    width: 100%;
    max-width: 100%; /* Ensure the page doesn't exceed 100% of the container width */
    margin: 0 auto;
    /* background-color: beige;
    height: 100vh; */
}

.course-details-page h2 {
    text-align: center;
    margin: 10px 0;
}

.student-details-section {
    display: flex;
    height: 100px; /* Increased height for better visibility */
    width: 100%;
    margin-top: 20px;
}

.profile-pic-wrapper {
    min-width: 100px; /* Increased width for larger profile picture */
    margin-right: 20px;
}

.profile-pic {
    height: 100px; /* Match height with parent container */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}

.student-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.course-name {
    font-size: 14px;
    color: #555;
}

.nav-item {
    padding: 5px;
}

.course-details-page .save-button {
    width: 100%;
    height: 60px;
    font-size: 1em;
    letter-spacing: 3px;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;
}

.nav-justified .nav-item .nav-link {
    width: 100%;
    height: 90px;
    border-radius: 15px;
    background-image: linear-gradient(to right, rgba(186, 35, 171, 1), rgba(87, 151, 225, 1));
    color: #ffffff;
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
}

.nav-justified .nav-item .nav-link.active {
    background-image: linear-gradient(to right, rgba(87, 151, 225, 1), rgba(186, 35, 171, 1));
    color: #000000;
    font-weight: bold;
}

.tab-content .active {
    background-color: #ffffff;
}

.transactions-section {
    max-width: 100%;
}

.transactions-table {
    width: 100%;
    margin-top: 20px;
    overflow: auto;
}

/* Flexbox to keep tabs in a single row */
.nav-justified {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.nav-justified .nav-item {
    flex: 1;
    margin-right: 10px;
}

.nav-justified .nav-item:last-child {
    margin-right: 0;
}

@media (max-width: 600px) {
    .nav-justified .nav-item {
        margin-right: 5px;
    }

    .nav-justified .nav-item:last-child {
        margin-right: 0;
    }

    .nav-justified .nav-item .nav-link {
        font-size: 10px;
        padding: 5px;
    }
}

@media (min-width: 991px) {
    .course-details-page {
        width: 80%;
    }

    .course-details-page .save-button {
        position: static;
        border-radius: 8px;
    }

    .transactions-table {
        overflow: hidden;
    }
}
